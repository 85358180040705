import {
  APP_NAME,
  LANG,
  THREAD,
  USER_AGENT,
  VERSION,
} from '../consts/application';
import { IBacktraceData } from '../model/backtraceData';
import { BacktraceStackTrace } from '../model/backtraceStackTrace';
import { Breadcrumbs } from '../model/breadcrumbs';
import { currentTimestamp, uuid } from '../utils';
import { IBreadcrumb } from './breadcrumbs';

/**
 * BacktraceReport describe current exception/message payload message to Backtrace
 */
export class BacktraceReport {
  // report id
  public readonly uuid: string = uuid();
  // timestamp
  public readonly timestamp = currentTimestamp();
  // lang
  public readonly lang = LANG;
  // environment version
  public readonly langVersion = USER_AGENT;
  // Backtrace-node name
  public readonly agent = APP_NAME;
  // Backtrace-js  version
  public readonly agentVersion = VERSION;
  // main thread name
  public readonly mainThread = THREAD;

  public sourceCode: { text: string } | undefined;

  public classifiers: string[] = [];

  /**
   * BacktraceReport generated by library allows you to
   * automatically send reports to Backtrace via send method
   * @deprecated Please use `client.sendReport` instead
   */
  public send!: (callback: (err?: Error) => void) => void | undefined;

  /**
   * BacktraceReport generated by library allows you to
   * automatically send reports to Backtrace via send method
   * @deprecated Please use `client.sendReport` instead
   */
  public sendSync!: (callback: (err?: Error) => void) => void | undefined;

  /**
   * Thread information about current application
   */
  public stackTrace!: BacktraceStackTrace;

  /**
   * Current report attributes
   */
  private attributes: { [index: string]: any } = {};

  /**
   * Backtrace complex objet
   */
  private annotations: { [index: string]: any } = {};

  /**
   * Create new BacktraceReport - report information that will collect information
   * for Backtrace.
   *
   * Possible existing scenarios:
   * arg1: error + arg2: attributes = all required
   * arg1: object, arg2: nothing
   *
   * @param err Error or message - content to report
   * @param clientAttributes Report attributes dictionary
   * @param breadcrumbs list of breadcrumbs to include as an attachment
   * @param attachment an client-provided attachment
   */
  constructor(
    private err: Error | string = '',
    private clientAttributes: { [index: string]: any } = {},
    private breadcrumbs?: IBreadcrumb[],
    private attachment?: string | object,
  ) {
    if (!clientAttributes) {
      clientAttributes = {};
    }
    this.splitAttributesFromAnnotations(clientAttributes);
    this.setError(err);
  }
  /**
   * Check if report contains exception information
   */
  public isExceptionTypeReport(): boolean {
    return this.detectReportType(this.err);
  }

  public getPayload(): Error | string {
    return this.err;
  }
  /**
   * Set error or message in BacktraceReport object
   * @param err Current error
   */
  public setError(err: Error | string): void {
    this.err = err;
    this.classifiers = this.detectReportType(err) ? [err.name] : [];
  }

  public log(log: string) {
    console.log(log);
  }

  public trace(log: string) {
    console.trace(log);
  }

  /**
   * Add new attributes to existing report attributes
   * @param attributes new report attributes object
   */
  public addObjectAttributes(attributes: { [index: string]: any }): void {
    this.clientAttributes = {
      ...this.clientAttributes,
      ...this.attributes,
      ...attributes,
    };
  }

  public addAttribute(key: string, value: any): void {
    this.clientAttributes[key] = value;
  }

  public addAnnotation(key: string, value: object): void {
    this.annotations[key] = value;
  }

  public toJson(): IBacktraceData {
    // why library should wait to retrieve source code data?
    // architecture decision require to pass additional parameters
    // not in constructor, but in additional method.
    this.collectReportInformation();

    const data = {
      uuid: this.uuid,
      timestamp: this.timestamp,
      lang: this.lang,
      langVersion: this.langVersion,
      mainThread: this.mainThread,
      classifiers: this.classifiers,
      threads: { main: this.stackTrace.toJson() },
      agent: this.agent,
      agentVersion: this.agentVersion,
      annotations: this.annotations,
      attributes: this.attributes,
    } as IBacktraceData;

    if (this.sourceCode && this.sourceCode.text) {
      data.sourceCode = { main: this.sourceCode };
    }

    if (this.attributes['symbolication_id']) {
      data.symbolication = 'sourcemap';
    }

    return data;
  }

  public toFormData(): FormData {
    const reportJson = this.toJson();
    const formData = new FormData();
    const blob = new Blob([JSON.stringify(reportJson)]);
    formData.append('upload_file', blob, 'upload_file.json');

    if (this.breadcrumbs) {
      const breadcrumbBlob = new Blob([JSON.stringify(this.breadcrumbs)]);
      formData.append(
        Breadcrumbs.attachmentName,
        breadcrumbBlob,
        Breadcrumbs.attachmentName,
      );
    }

    if (this.attachment) {
      const attachmentBlob = new Blob([JSON.stringify(this.attachment)]);
      const attachmentName = `attachment_${Date.now()}`;
      formData.append(attachmentName, attachmentBlob, attachmentName);
    }
    return formData;
  }

  /**
   * @deprecated This method is not supported
   */
  public setSourceCodeOptions(tabWidth: number, contextLineCount: number) {
    // not supported
  }

  private collectReportInformation(): void {
    // get stack trace to retrieve calling module information
    this.stackTrace = new BacktraceStackTrace(this.err);
    // combine attributes
    this.attributes = {
      ...this.readErrorAttributes(),
      ...this.clientAttributes,
    };
    // combine annotations
    this.annotations = this.readAnnotation();
  }

  private detectReportType(err: Error | string): err is Error {
    return err instanceof Error;
  }

  private readErrorAttributes(): object {
    if (!this.detectReportType(this.err)) {
      return {
        'error.message': this.err,
      };
    }
    this.classifiers = [this.err.name];
    return {
      'error.message': this.err.message,
    };
  }

  private readAnnotation(): object {
    return {
      Memory: window.performance ? window.performance : performance.timing,
      Geolocation: navigator.geolocation,
      Screen: {
        'screen.availHeight': window.screen.availHeight,
        'screen.availWidth': window.screen.availWidth,
        'screen.height': window.screen.height,
        'screen.width': window.screen.width,
        'screen.orientation': window.screen.orientation.type,
        'screen.colorDepth': window.screen.colorDepth,
        'screen.pixelDepth': window.screen.pixelDepth,
      },
      Exception: this.getSerializableError(),
      ...this.annotations,
    };
  }

  private getSerializableError() {
    if (typeof this.err === 'string') {
      return {
        message: this.err,
      };
    }
    const serializableError: { [index: string]: string } = {};
    const self = this;
    Object.getOwnPropertyNames(this.err).forEach((key) => {
      serializableError[key] = (self.err as { [index: string]: any })[key];
    });
    return serializableError;
  }

  private splitAttributesFromAnnotations(clientAttributes: {
    [index: string]: any;
  }) {
    for (const key in clientAttributes) {
      if (clientAttributes.hasOwnProperty(key)) {
        const element = this.clientAttributes[key];
        if (!element) {
          continue;
        }
        if (typeof element === 'object') {
          this.annotations[key] = element;
        } else {
          this.attributes[key] = element;
        }
      }
    }
  }
}
